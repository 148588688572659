var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('UserCard',{attrs:{"user":_vm.user,"personMeta":_vm.personMeta,"pmsMeta":_vm.pmsMeta,"user-id":_vm.userId},on:{"account:delete":_vm.handleDeleteAccount,"phone:add":_vm.handleNewPhoneWithoutVerification,"email:add":_vm.handleNewEmailWithoutVerification,"drop:password":_vm.handleDropPassword,"activate":_vm.handleActivateUser,"deactivate":_vm.handleDeactivateUser}})],1)],1),(_vm.isUserActive)?_c('VRow',{staticClass:"pb-8"},[_c('VCol',[_c('h3',{staticClass:"py-3"},[_vm._v(" Штатные должности ")]),_vm._l((_vm.companies),function(company){return _c('VDataTable',{key:company.id,staticClass:"mt-8",attrs:{"items":_vm.staffPositionsCompanyFilter(company),"headers":_vm.usersStaffPositionsHeaders,"loading":_vm.usersStaffPositionsLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex mb-2"},[_c('RouterLink',{attrs:{"to":{
                name : _vm.Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE,
                params : {
                  accountId : company.accountId,
                  companyId : company.id
                }
              }}},[_vm._v(" "+_vm._s(company.name)+" ")]),_c('div',{staticClass:"ml-5"},[(company.active)?_c('TTChip',{staticClass:"tt-light-green pale tt-light-green--text"},[_vm._v("Активная")]):_c('TTChip',{staticClass:"tt-light-red pale tt-light-red--text"},[_vm._v("Не активная")])],1),_c('div',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(company.id)+" ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
              name : _vm.Names.R_ORGSTRUCTURE_POSITION_EDIT,
              params : {
                positionId : item.id
              }
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.teamName",fn:function(ref){
            var item = ref.item;
return [_c('RouterLink',{attrs:{"to":{
              name : _vm.Names.R_ORGSTRUCTURE_COMPANY_TEAM,
              params : {
                companyId : item.companyId,
                teamId : item.teamId
              }
            }}},[_vm._v(" "+_vm._s(item.teamName || item.teamDisplayName)+" ")])]}},{key:"item.main",fn:function(ref){
            var item = ref.item;
return [(item.main)?_c('VIcon',{attrs:{"color":"warning","small":""}},[_vm._v(" fas fa-star ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"tt-light-blue","icon":"","small":"","to":{
                  name : _vm.Names.R_ORGSTRUCTURE_USER_COMPANY,
                  params : {
                    personId : _vm.userId,
                    companyId : company.id,
                    staffPosition : item.id
                  },
                  query : {
                    staffPosition : item.id
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-network-wired ")])],1)]}}],null,true)},[_c('span',[_vm._v("Связи ШД")])])]}}],null,true)})})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }